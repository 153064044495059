import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types';
import Logo from '../../assets/icons/logo-dark.inline.svg';
import Facebook from '../../assets/icons/facebook.inline.svg';
import Instagram from '../../assets/icons/instagram.inline.svg';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function Footer({ title, description, inputLabel, fbUrl, igUrl, items}) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const url = process.env.GATSBY_API_URL + 'notifications/subscribe-newsletter'
    setError(false);
    if (!validateEmail(email)) {
      setError(true);
    }
    setLoading(true);
    try {
      await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      });
      setSubscribed(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [email]);

  return (
    <div className="container mt-60 pb-30 lg:mt-160">
      <Logo className="h-20 w-auto md:h-30"/>
      <div className="lg:flex justify-between items-end mb-30">
        <div>
          <div className="lg:flex hidden items-center">
            <a href={fbUrl} className="mr-24"><Facebook className="h-20"/></a>
            <a href={igUrl}><Instagram className="h-22"/></a>
          </div>
        </div>
        <div className="bg-gray-100 p-20 lg:p-40 mt-40" >
          <h4 className="text-23 text-green-300 hk-semibold leading-tight mb-10">{title}</h4>
          <p className="text-18 hk-medium leading-relaxed mb-40 lg:mb-32">{description}</p>
          {!subscribed && (
            <form method="POST" onSubmit={onSubmit}>
              <div className="text-15 pb-12 border-b border-solid border-gray-350 flex justify-between">
                <input
                  type="email"
                  placeholder={inputLabel}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-transparent outline-none text-gray-350"
                  required
                />
                <button
                  type="submit"
                  className="text-green-300 font-bold"
                  disabled={loading}
                >
                  Send
                </button>
              </div>
              {error && (
                <p className="text-red">Invalid email</p>
              )}
            </form>
          )}
          {subscribed && (
            <p className="text-18 font-medium text-green-300">Subscribed!</p>
          )}
        </div>
        <div className="flex lg:hidden mt-40">
          <a href={fbUrl} className="mr-24"><Facebook /></a>
          <a href={igUrl}><Instagram /></a>
        </div>
      </div>
      <div className="lg:flex justify-between text-13">
        <div className="lg:flex">
          <span className="mr-40 text-gray-500 mb-10 lg:mb-0 block">© {new Date().getFullYear()} Togenesis</span>
          {items.map((item, index) => (
            <a
              key={index.toString()}
              target="_blank"
              rel="noreferrer"
              href={item.linkUrl}
              className="mr-40 text-gray-500 mb-10 lg:pb-0 block"
            >
              {item.linkLabel}
            </a>
          ))}
        </div>
        <a
          href="https://mono.studio"
          target="_blank"
          rel="noreferrer"
          className="text-13 pt-10 lg:pt-0 font-medium"
        >
          Designed with <span className="text-red">♥</span> by <b>mono.</b>
        </a>
      </div>
    </div>


  );
}

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  fbUrl: PropTypes.string.isRequired,
  igUrl: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    linkLabel: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired
  }).isRequired),
};

export default Footer;
