import React from 'react'
import PropTypes from 'prop-types';

function EnvironmentalImpact({ title, description, items }) {
  return(
    <div className="environmental-impact__background relative lg:rounded-bl-small" id="environmental-impact">
      <div className="container pl-30 md:pl-0 py-20 lg:py-50">
        <h4 className="text-23 hk-semibold md:text-30 mb-20">{title}</h4>
        <p className="text-15 md:text-17 text-opacity-75 leading-loose mb-40 text-gray-550">{description}</p>
        <div className="lg:grid grid-cols-3 gap-x-30">
          {items.map((item, index) => (
            <div
              className={'item border-l border-solid border-black pl-20 lg:mb-0 ' + (index < items.length - 1 ? 'mb-30' : '')}
              key={index.toString()}
            >
              <h3 className="text-red text-27 md:text-34 font-bold mb-20">{item.percentage}</h3>
              <h6 className="text-18 md:text-20 font-semibold">{item.title}</h6>
              <p className="text-14">{item.source}</p>
              <p className="mt-10 text-16 text-gray-550">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

EnvironmentalImpact.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    percentage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired),
};

export default EnvironmentalImpact;
