import React from 'react'
import PropTypes from 'prop-types';
import ArrowRight from '../../assets/icons/arrow-green-right.inline.svg';

function CardsGrid({ title, cards, ctaLabel, ctaDescription, ctaImages }){
  return (
    <div className="container">
      <h3 className="text-23 md:text-30 hk-semibold leading-snug mb-40">{title}</h3>
      <div className="lg:grid grid-cols-4 gap-10">
        <div className="card bg-gray-100 pt-30 rounded-small col-span-2 mb-20 lg:mb-0">
          <h4 className="text-18 md:text-20 mb-50 px-30 leading-relaxed font-medium">{cards[0].description}</h4>
          <img
            srcSet={
              cards[0].images[0] + ' 1x,' +
              cards[0].images[1] + ' 2x,' +
              cards[0].images[2] + ' 3x'
            }
            src={cards[0].images[0]}
            alt="citizen"
            className="mx-auto px-20 lg:px-0"
          />
        </div>
        <div className="card bg-gray-100 pt-30 rounded-small col-span-2 mb-20 lg:mb-0">
          <h4 className="text-18 md:text-20 mb-50 px-30 leading-relaxed font-medium">{cards[1].description}</h4>
          <img
            srcSet={
              cards[1].images[0] + ' 1x,' +
              cards[1].images[1] + ' 2x,' +
              cards[1].images[2] + ' 3x'
            }
            src={cards[1].images[0]}
            alt="totem"
            className="w-auto pl-20 lg:ml-auto pb-8 lg:pb-0"
          />
        </div>
        <div className="card bg-gray-100 rounded-small col-span-4 lg:grid grid-cols-2">
          <div className="lg:py-150 pb-40 pl-30 py-150">
            <p className="text-18 md:text-20 leading-relaxed mb-15 font-medium">{ctaDescription}</p>
            <a
              href="https://store.togenesis.eu"
              target="_blank" rel="noreferrer"
              className="text-green-300 text-15 font-bold flex items-center"
            >
              {ctaLabel}
              <ArrowRight className="ml-10" />
            </a>
          </div>
          <img
            srcSet={
              ctaImages[0] + ' 1x,' +
              ctaImages[1] + ' 2x,' +
              ctaImages[2] + ' 3x'
            }
            src={ctaImages[0]}
            alt="store"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}

CardsGrid.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired
  }).isRequired),
  ctaLabel: PropTypes.string.isRequired,
  ctaDescription: PropTypes.string.isRequired,
  ctaImages: PropTypes.array.isRequired,
};


export default CardsGrid;
