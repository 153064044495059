import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Logo from '../../assets/icons/logo-white.inline.svg';
import ArrowDown from '../../assets/icons/arrow-down.inline.svg';
import './style.css';

function Hero(props){
  const {
    titles,
  } = props;
  const nextSection = useCallback(() => {
    const element = document.getElementById('environmental-impact');
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      })
    }
  }, []);
  return (
    <section className="hero">
      <div className="background relative w-screen h-screen bg-no-repeat bg-center bg-gray-600">
        <div className="header pt-70 lg:pt-60 lg:pl-165">
          <Logo className="header__logo h-28 md:h34 md:h-auto"/>
        </div>
        <div className="text-center text-white absolute top-half transform -translate-y-1/2 px-20 lg:left-half lg:-translate-x-1/2">
          {titles.map((title, index) => (
            <h2 key={index.toString()} className={cn(['hero__title text-26 md:text-30 hk-medium'], {'mb-40' : index < titles.length - 1})}>{title}</h2>
          ))}
        </div>
        <button type="button" className="arrow-btn" onClick={nextSection}>
          <ArrowDown />
        </button>
      </div>
    </section>
  );
}

Hero.propTypes = {
  titles: PropTypes.array.isRequired,
};

export default Hero;
