import React from 'react'
import PropTypes from 'prop-types';

function ForCompanies({ subTitle, title, description, groupTitle, items }){
  return (
    <div className="for-companies container">
      <div className="lg:flex bg-gray-600 rounded-small py-20 lg:pt-80 lg:pb-55 pl-20 lg:pl-60">
        <div>
          <div className="pr-20">
            <h6 className="uppercase text-13 text-green-300 hk-semibold mb-10 md:mb-13">{subTitle}</h6>
            <h4 className="text-white text-23 md:text-35 leading-snug mb-10 md:mb-20 hk-semibold">{title}</h4>
            <p className="text-white text-opacity-70 text-15 md:text-17 leading-loose mb-50">{description}</p>
          </div>
          <img
            srcSet={
              '/images/togenesis-world.png 1x,' +
              '/images/togenesis-world@2x.png 2x,' +
              '/images/togenesis-world@3x.png 3x'
            }
            src="/images/togenesis-world.png"
            alt="togenesis world"
            className="object-contain lg:hidden"
          />
          <div>
            <h5 className="uppercase text-white text-15 md:text-16 mb-20 hk-semibold">{groupTitle}</h5>
            {items.map((item, index) => (
              <div className="relative item text-15 md:text-16 mb-20 pl-15" key={index.toString()}>
                <p className="text-white">{item.goal}</p>
              </div>
            ))}
          </div>
        </div>
        <img
          srcSet={
            '/images/togenesis-world.png 1x,' +
            '/images/togenesis-world@2x.png 2x,' +
            '/images/togenesis-world@3x.png 3x'
          }
          src="/images/togenesis-world.png"
          alt="togenesis world"
          className="hidden lg:block object-contain object-top"
        />
      </div>
    </div>

  );
}

ForCompanies.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  groupTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    goal: PropTypes.string.isRequired,
  }).isRequired),
};

export default ForCompanies;
