import React from 'react'
import PropTypes from 'prop-types';

function StatisticsMap({ subTitle, title, description, items }){
  return (
    <section className="statistics-map bg-no-repeat bg-cover bg-center pt-40 lg:pt-0">
      <div className="container lg:grid grid-cols-12 gap-30">
        <div className="hidden lg:flex col-start-2 col-span-4 items-center justify-center">
          <img
            srcSet={
              '/images/pin.png 1x,' +
              '/images/pin@2x.png 2x,' +
              '/images/pin@3x.png 3x'
            }
            src="/images/pin.png"
            alt="pin"
          />
        </div>
        <div className="bg-white p-20 lg:p-40 col-span-7 lg:my-128 mb-20">
          <div>
            <h6 className="uppercase text-green-300 text-13 mb-13 hk-semibold">{subTitle}<span className="text-red"> ❤</span></h6>
            <h3 className="text-23 md:text-35 mb-10 hk-semibold">{title}</h3>
            <p className="text-15 md:text-17 font-medium mb-30 lg:mb-60 leading-loose">{description}</p>
          </div>
          <div className="lg:grid grid-cols-3">
            <div className="item border-l border-solid border-green-300 px-20 mb-20 lg:mb-0">
              <h3 className="uppercase text-23 md:text-28 text-green-300 font-bold mb-10">{items[0].value}</h3>
              <h6 className="text-14 font-semibold mb-5">{items[0].title}</h6>
              <p className="text-gray-550 text-14 ">{items[0].description}</p>
            </div>
            <div className="item border-l border-solid border-green-300 px-20 mb-20 lg:mb-0">
              <h3 className="uppercase text-23 md:text-28 text-green-300 font-bold mb-10">{items[1].value}</h3>
              <h6 className="text-14 font-semibold mb-5">{items[1].title}</h6>
              <p className="text-gray-550 text-14 ">{items[1].description}</p>
            </div>
            <div className="item border-l border-solid border-green-300 pl-20 mb-20 lg:mb-0">
              <h3 className="uppercase text-23 md:text-28 text-green-300 font-bold mb-10">{items[2].value}</h3>
              <h6 className="text-14 font-semibold mb-5">{items[2].title}</h6>
              <p className="text-gray-550 text-14 ">{items[2].description}</p>
            </div>
          </div>
        </div>
        <div className="lg:hidden col-span-12 flex justify-end pb-55">
          <img
            srcSet={
              '/images/pin.png 1x,' +
              '/images/pin@2x.png 2x,' +
              '/images/pin@3x.png 3x'
            }
            src="/images/pin.png"
            alt="pin"
          />
        </div>
      </div>
    </section>
  );
}

StatisticsMap.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired)
};

export default StatisticsMap;
