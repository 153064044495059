import React, { useMemo } from 'react'

import Hero from '../components/Hero';
import EnvironmentalImpact from '../components/EnvironmentalImpact';
import CardsGrid from '../components/CardsGrid';
import StatisticsMap from '../components/StatisticsMap'
import ForCompanies from '../components/ForCompanies';
import Footer from '../components/Footer';
import SEO from '../components/seo'
import LogosGrid from '../components/LogosGrid';

function IndexPage() {
  const environmentalImpact = useMemo(() => ({
    title: 'Environmental impact',
    description: 'In the last decades environmental pollution is constantly increasing. Environmental protection is one of our major goals, as it directly involves your future.',
    items: [
      {
        percentage: '+0,7%',
        title: 'Increase of waste generated in the EU',
        source: 'Source eurostat',
        description: 'The amount of waste production exceeded 217 million tons.'
      },
      {
        percentage: '65%',
        title: 'Recycling target for municipal waste',
        source: 'Source eu commission',
        description: 'EU wants to reach this target by 2030.'
      },
      {
        percentage: '30%',
        title: 'The recycling of plastics',
        source: 'Source eu commission ',
        description: 'Not all plastics packaging can be recycled. We save plastics from plastics. '
      },
    ]
  }), []);
  const cardsGrid = useMemo(() => ({
    title: 'How we improve the environment together',
    ctaLabel: 'Visit store.togenesis.eu',
    ctaDescription: 'You will get points for every order picked up and you will be able to choose a reward from the Togenesis store.',
    ctaImages: [
      '/images/store.png',
      '/images/store@2x.png',
      '/images/store@3x.png',
    ],
    cards: [
      {
        description: 'The App allows citizens to order on demand the collection of domestic waste in a few steps from the comfort of their home.',
        images: [
          '/images/citizen.png',
          '/images/citizen@2x.png',
          '/images/citizen@3x.png',
        ]
      },
      {
        description: 'Let\'s find the totems around your city! You will find out how much good your city is doing to the environment and the statistics of CO2 emissions avoided.',
        images: [
          '/images/totem.png',
          '/images/totem@2x.png',
          '/images/totem@3x.png',
        ]
      },
    ]
  }), []);
  const statisticsMap = useMemo(() => ({
    subTitle: 'testing phase in Sweden',
    title: 'Hej super citizen',
    description: 'We are testing the Togenesis App in Sweden to estimate the impact it has on the environment. Download the Togenesis App to improve the environment together!',
    items: [
      {
        value: '5K',
        title: 'People engaged',
        description: 'In the circular economy by Togenesis.'
      },
      {
        value: '500',
        title: 'Total requests',
        description: 'The total amount of how much you recyclied.'
      },
      {
        value: '2600 KG',
        title: 'CO2 emissions avoided',
        description: 'Thanks to the synergistic work of citizens and companies.'
      }
    ]
  }), []);
  const forCompanies = useMemo(() => ({
    subTitle: 'For companies and waste managers',
    title: 'A digital platform for zero waste',
    description: 'Are you a company that wants to collect end-of-life products and packaging? Are you an environmental manager that wants to improve your collection operations and save resources? Get in touch with us! We will work hand in hand to achieve your sustainability goals.',
    groupTitle: 'in one year',
    items: [
      {
        goal: '+ 40% of the withdrawals made',
      },
      {
        goal: '+90% of withdrawal requests',
      },
      {
        goal: '-20% of the time spent organizing driver exits',
      }
    ],
  }), []);
  const footer = useMemo(() => ({
    title: 'Subscribe our newsletter',
    description: 'Change and improve the lives of millions of people for a better world.',
    inputLabel: 'Insert your e-mail',
    fbUrl: '/',
    igUrl: '/',
    items: [
      {
        linkLabel: 'Terms and conditions',
        linkUrl: '',
      },
      {
        linkLabel: 'Privacy policy',
        linkUrl: '',
      },
      {
        linkLabel: 'Cookie',
        linkUrl: '',
      }
    ]
  }), []);

  const logosGrid = useMemo( () => ({
    label: 'Our partners and clients',
    items: [
      {
        image: '/images/nsr.svg',
        alt: 'NSR',
        height: 28,
      },
      {
        image: '/images/packbridge.png',
        alt: 'Packbridge',
        height: 55,
      },
      {
        image: '/images/h22.png',
        alt: 'H22',
        height: 44,
      }
    ],
  }), []);
  return (
    <>
      <SEO />
      <Hero
        titles={['We work towards a zero-waste goal by helping companies repossess packaging and the end-of-life products.', 'We bring citizens, waste managers and companies together in a single digital platform that closes a loop between the involved parties.']}
      />
      <div className="mb-60 md:mb-160">
        <EnvironmentalImpact {...environmentalImpact} />
      </div>
      <div className="mb-60 md:mb-160">
        <CardsGrid {...cardsGrid} />
      </div>
      <div className="mb-60 md:mb-160">
        <StatisticsMap {...statisticsMap} />
      </div>
      <div className="mb-60 md:mb-160">
        <ForCompanies {...forCompanies} />
      </div>
      <div className="mt-60 md:my-140">
        <LogosGrid {...logosGrid} />
      </div>
      <Footer {...footer} />
    </>
  );
}

export default IndexPage;
