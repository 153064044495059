import React from 'react'
import PropTypes from 'prop-types';
import cn from 'classnames';

function LogosGrid({ label, items }){
  return (
   <section className="container">
     <div className="md:flex justify-between">
       <h3 className="md:mr-30 lg:mr-0 mb-60 md:mb-0 text-23 md:text-30 hk-semibold text-gray-600 leading-tight md:leading-snug">
         { label }
       </h3>
       <div className="flex flex-col md:flex-row items-center">
         {items.map((item, index) => (
           <div className={cn(['md:mb-0', {'mb-60 md:mr-45 lg:mr-90 xl:mr-114' : index !== items.length - 1}])} key={index.toString()}>
             <img src={item.image} alt={item.alt} style={{ height: `${item.height}px` }} />
           </div>
         ))}
       </div>
     </div>
   </section>
  );
}

LogosGrid.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  }).isRequired)
};

export default LogosGrid;
